import { RequestResponse } from '../../DTO/requestResponse';
import { Request } from "../../CONSTANTS/request";
import { MICROSERVICIOS, TOPDESK_CONFIG } from "../../CONSTANTS/constants";




export default class Topdesk {

    // Validacion Existencia otros Tickets

    // ticket de cambio
    static async ValidaCambioTicket(OrdenFlete: any, fecha: any): Promise<RequestResponse> {
        let direccion = `operatorChanges?query=optionalFields2.text1==${OrdenFlete};creationDate>=${fecha}T00:00:00Z`;
        return new Request(MICROSERVICIOS.TOPDESK).getTopdeskValidacion(direccion);
    }

    // ticket de incidente
    static async ValidaIncidenteTicket(OrdenFlete: any, fecha: any ): Promise<RequestResponse> {
        let direccion = `incidents?query=optionalFields1.text2==${OrdenFlete};creationDate>=${fecha}T00:00:00Z`;
        return new Request(MICROSERVICIOS.TOPDESK).getTopdeskValidacion(direccion);
    }

    static async TraeTrickeResuleto(ticket: any): Promise<RequestResponse> {
        let direccion = `operatorChanges/${ticket}`;
        return new Request(MICROSERVICIOS.TOPDESK).getTopdesk(direccion);
    }

    // Current User
    static async ObtieneUsuario(): Promise<RequestResponse> {
        let direccion = `persons/current`;
        return new Request(MICROSERVICIOS.TOPDESK).getTopdeskValidacion(direccion);
    }
}