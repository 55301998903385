
import React from "react";
import "../ASSETS/CSS/main.css";

import logo from "../../src/ASSETS/IMAGES/logo-starken.png";

export default function View404() {
  return (
    <div className="containerPrincipal" style={{ background: "white" }}>
      <div className="containerSecundario ">
    
          <div style={{ marginBottom: 20 }}>
            <img alt="banner" src={logo} />
          </div>
     

          <div style={{ marginBottom: 20 }}>
            <h2>Error 404</h2>
            <h5>La dirección no Existe.</h5>
          </div>
       
      </div>
    </div>
  );
}
