let poderAncestral = window.location.ancestorOrigins[0];

let autorizar: boolean = false;

if (window.location.host.toLocaleLowerCase().includes("localhost")) {
  autorizar = true;
} else if (poderAncestral !== undefined) {
  if (window.location.ancestorOrigins[0].toLocaleLowerCase().includes("starken.topdesk.net")) {
    autorizar = true;
  }
}

var referrer = document.referrer;
console.log("referrer url", referrer);

export const inicio = {
  permitido: autorizar,
}

let TOPDESK: string = "" + process.env.REACT_APP_TOPDESK;
let TOPDESK_SUB_CATEGORY: string = "" + process.env.REACT_APP_SUB_CATEGORY_ID;
let TOPDESK_PAGE_SIZE: string = "" + process.env.REACT_APP_PAGE_SIZE;
let TRACKING_DETALLE: string = "" + process.env.REACT_APP_TRACKING;



// ESTADOS
const ENTREGADO: number = Number(process.env.REACT_APP_ENTREGADO);
const CERRADO_EXCEPCION: number = Number(process.env.REACT_APP_CERRADO_EXCEPCION);
const REZAGO: number = Number(process.env.REACT_APP_REZAGADO);
const REDESTINADO: number = Number(process.env.REACT_APP_REDESTINADO);
const DECOMISADO: number = Number(process.env.REACT_APP_DECOMISADO);
const ANULADO: number = Number(process.env.REACT_APP_ANULADO);
const DEVUELTO: number = Number(process.env.REACT_APP_DEVUELTO);
const REZAGO_PARCIAL: number = Number(process.env.REACT_APP_REZAGO_PARCIAL);
const PENDIENTE_ENTREGA: number = Number(process.env.REACT_APP_PENDIENTE_ENTREGA);

export const ESTADOS = {
  ENTREGADO: ENTREGADO,
  CERRADO_EXCEPCION: CERRADO_EXCEPCION,
  REZAGO: REZAGO,
  REDESTINADO: REDESTINADO,
  DECOMISADO: DECOMISADO,
  ANULADO: ANULADO,
  DEVUELTO: DEVUELTO,
  REZAGO_PARCIAL: REZAGO_PARCIAL,
  PENDIENTE_ENTREGA: PENDIENTE_ENTREGA
}


const BASE_FOMULARIO_ENVIO: string = String(process.env.REACT_APP_URL_BASE_FORMULARIO_ENVIO);

export const BASE_FOMULARIO = {
  url: BASE_FOMULARIO_ENVIO
}




export const MICROSERVICIOS = {

  // topdesk
  TOPDESK:                   // base topdesk
    TOPDESK,

  // TRACKING
  TRACKING_DETALLE:                   //detalle
    TRACKING_DETALLE

}

export const TOPDESK_CONFIG = {

  SUB_CATEGORY: TOPDESK_SUB_CATEGORY,
  PAGE_SIZE: TOPDESK_PAGE_SIZE

}



// USER CRM

let CRM_USER: string = "" + process.env.REACT_APP_CRM_USER;
let CRM_PASS: string = "" + process.env.REACT_APP_CRM_PASS;

export const AUTENTICACION_CRM = {
  user: CRM_USER,
  pass: CRM_PASS
}


// USER CONSULTA TOPDESK
let CONSULTA_TDK_USER: string = "" + process.env.REACT_APP_CONSULTA_TDK_USER;
let CONSULTA_TDK_PASS: string = "" + process.env.REACT_APP_CONSULTA_TDK_PASS;

export const CONSULTA_TDK = {
  user: CONSULTA_TDK_USER,
  pass: CONSULTA_TDK_PASS
}


// USER TOPDESK AGENCIAS
let TDK_USER_AGENCIAS: string = "" + process.env.REACT_APP_USER_TDK_AGENCIAS;
let TDK_PASS_AGENCIAS: string = "" + process.env.REACT_APP_PASS_TDK_AGENCIAS;

export const AUTENTICATION_TDK_AGENCIAS = {
  user: TDK_USER_AGENCIAS,
  pass: TDK_PASS_AGENCIAS
}


// USER VALIDACION TOPDESK
let TDK_USER_VALIDACION: string = "" + process.env.REACT_APP_USER_TDK_VALIDACION;
let TDK_PASS_VALIDACION: string = "" + process.env.REACT_APP_PASS_TDK_VALIDACION;

export const AUTENTICATION_TDK_VALIDACION = {
  user: TDK_USER_VALIDACION,
  pass: TDK_PASS_VALIDACION
}

let TIME_OUT: number = Number(process.env.REACT_APP_REQUEST_TIMEOUT);

export const REQUEST_TIMEOUT = TIME_OUT; // segundos

let DIAS_ANTERIORES: number = Number(process.env.REACT_APP_DIAS_ANTERIORES);

export const TDK_DIAS_ANTERIORES = DIAS_ANTERIORES;


let TDK_MESES_VALIDACION: string = "" + process.env.REACT_APP_MESES_VALIDACION;

export const VALIDACIONES = {
  TDK_MESES_VALIDACION: TDK_MESES_VALIDACION
}








