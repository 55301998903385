import React, { useState, useEffect } from "react";

import { Button, Alert, Modal, Spinner, Form, Tooltip } from "react-bootstrap";

import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faInfoCircle, faSearch } from "@fortawesome/free-solid-svg-icons";
import Menu from "../../CONTAINERS/menu/menu";


import Tracking from "../../API/MICROSERVICES/TRACKING/tracking";
import { consultaTrackingDetalleDto } from "../../API/MICROSERVICES/TRACKING/consultaTrackingDetalle.dto";
import AlertaCentral from "../../SHARED/CentralAlertas";
import { BASE_FOMULARIO, ESTADOS, inicio, VALIDACIONES } from "../../API/CONSTANTS/constants";
import Topdesk from "../../API/MICROSERVICES/TOPDESK/topdesk";


type FormElement = React.FormEvent<HTMLFormElement>;


export default function ValidadorOf(props: any) {


    const { history } = props;

    const [mostrar, setMostrar] = useState(false);
    const [ordenFlete, setOrdenFlete] = useState("");

    let mensa: string;

    const [value, setValue] = useState('');

    const [showU, setShowU] = useState(false);

    const [MostrarBoton, setMostrarBoton] = useState(false);

    function validacion() {
        if (inicio.permitido === false) {
            PaginaError();
        }
    }

    function PaginaError() {
        history.push("/InValidDomain");
    }

    useEffect(() => {
        validacion();
    }, [])

    //=====================================================================================

    // ==========================================================================================================================================
    // SUBMIT PRINCIPAL 
    const handleSubmit = (e: FormElement) => { // inicio handleSubmit
        e.preventDefault();
        enviarSubmit();
    }; // final handleSubmit

    function enviarSubmit() {
        // //console.log("Enviando Consulta");
        setShowU(true);
        microservicioDetalle();
        setShowU(false);


    } // <-- Final Funcion enviar submit


    // METODO DE RESPALDO
    async function microservicioDetalle() {

        let largo = ordenFlete.length;

        if (largo > 9) {
            AlertaCentral("OFLARGA", "", "", ordenFlete);
        } else if (largo <= 2) {
            AlertaCentral("OFCORTA", "", "", ordenFlete);
        } else {
            let ordenf: number = Number(ordenFlete);
            let orden: consultaTrackingDetalleDto = {
                of: ordenf
            }

            let estado;

            try {

                let ordenFl: any = await Tracking.detalle(orden).then((resp) => {

                    if (resp.error) {

                        AlertaCentral("FALLAAPI", "", resp.error);

                    } else {

                        estado = resp.data.ordenFlete.codigoEstado;

                        let respuesta = {
                            status: resp.status,
                            existe: resp.data.ordenFlete.of

                        }

                        return respuesta;

                    }

                });

                if (ordenFl.status == 200 && ordenFl.existe !== null) {


                    if (estado === ESTADOS.CERRADO_EXCEPCION ||
                        estado === ESTADOS.REZAGO ||
                        estado === ESTADOS.REDESTINADO ||
                        estado === ESTADOS.DECOMISADO ||
                        estado === ESTADOS.ANULADO) {

                        let nombreEstado: string = TraductorEstado(estado);
                        AlertaCentral("OFCONESTADOINALTERABLE", "", "", ordenFlete, nombreEstado);

                    }

                    // else if (estado === ESTADOS.ENTREGADO ||
                    //     estado === ESTADOS.DEVUELTO) {

                    //     AlertaCentral("OFCONESTADOINALTERABLE", "", "", ordenFlete);

                    // }
                    else {

                    
                                            let top:any = await validarTopdesk(ordenFlete);
                    
                    
                                            console.log(top);
                    
                                            if(top.validador == true){
                                                AlertaCentral("OFCORRECTA");
                                                CargarFormulario(ordenFlete);
                    
                                            }else{
                                                AlertaCentral("OFCONTICKET", "", "", ordenFlete ,top.mensaje );
                    
                                            }
                    
                    }

                } else {
                    AlertaCentral("OFNOEXISTE", "", "", ordenFlete);
                }

            } catch (error) {

                AlertaCentral("ErrorNoDeterminado");

            }



        }
    }


    function TraductorEstado(estado: number) {
        let nombreEstado: string = "";

        switch (estado) {
            case 1:
                nombreEstado = "ENTREGADO";
                break;
            case 11:
                nombreEstado = "CERRADO CON EXCEPCION";
                break;
            case 12:
                nombreEstado = "REZAGADO";
                break;
            case 13:
                nombreEstado = "REDESTINADO";
                break;
            case 14:
                nombreEstado = "DECOMISADO";
                break;
            case 15:
                nombreEstado = "ANULADO";
                break;
            case 32:
                nombreEstado = "DEVUELTO";
                break;
            case 27:
                nombreEstado = "REZAGO PARCIAL";
                break;

        }

        return nombreEstado;

    }


    async function CargarFormulario(orden: any) {

        let of: string = `&odfl=${orden}`;
        let URL: string = BASE_FOMULARIO.url + of;
        abrirFormulario(URL);

    }


    function abrirFormulario(url: string) {
        window.open(url, "_self");
    }



    useEffect(() => {


        if (ordenFlete.length > 0) {

            setMostrarBoton(true);
        } else {
            setMostrarBoton(false);
        }


    }, [ordenFlete]);



    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value: inputValue } = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if (reg.test(inputValue.trim()) || inputValue.trim() === '' || inputValue.trim() === '-') {
            onChanteInput(inputValue.trim());
        }
    };

    const onChanteInput = (value: string) => {
        setOrdenFlete(value);
    }

    async function validarTopdesk(resultadoOrdenFlete: any) {

  
        let fechaActual = new Date();
    
        let MesesAntes = Number(VALIDACIONES.TDK_MESES_VALIDACION);
    
        let fechaAnterior = moment().add('month', -MesesAntes).format('YYYY-MM-DD');
    
        try {
    
            let incidenteResponse = await Topdesk.ValidaIncidenteTicket(resultadoOrdenFlete, fechaAnterior).then((resp:any) => {
               
                return resp;
            })
    
            let incidente = incidenteResponse.data;
    
            if(incidenteResponse.status !== 204){
                if (incidente != undefined) {
                    if (incidente != null) {
                        if (incidente != "") {
                            mensa = "" + incidente[0].number;
                        }
                    }
                }
            }
    
    
            let cambioResponse = await Topdesk.ValidaCambioTicket(resultadoOrdenFlete, fechaAnterior).then((resp) => {       
                return resp;
            })
    
            let cambio = cambioResponse.data.results;
    
            let validacionIncidente = incidente.length;
            let validacionCambio = cambio.length;
    
            if (validacionIncidente == 0 && validacionCambio == 0) {
    
                //  console.log("Puedo pasar");
    
                const respuesta = {
                    mensaje: "",
                    validador: true,
                }
    
                return respuesta;
    
            } else {
                const respuesta = {
                    mensaje: mensa,
                    validador: false,
                }
                return respuesta;
            }
    
    
        } catch (error) {
        
            const respuesta = {
    
                mensaje: "" + error,
                validador: false,
    
            }
    
            return respuesta;
        }
    
    }
    





    return (
        <>
            {/* MENU */}
            <Menu logo="" titulo="Validación Orden de Flete General" />

            {/* INFORMACION */}
            <Alert className="ml-1" show={mostrar} variant="success">
                <Alert.Heading> Información </Alert.Heading>
                <p>
                    Es necesario ingresar una orden de flete en <b> Válida </b> y  <b>Habilitada en el Sistema</b>.
                </p>

                <div className="d-flex justify-content-end">

                    <Button
                        className="outline-info"
                        onClick={() => setMostrar(false)}
                        variant="outline-info"
                        size="sm"
                    >
                        <FontAwesomeIcon icon={faAngleUp} />
                    </Button>

                </div>
            </Alert>

            {!mostrar && (

                <Button
                    variant="success"
                    className="rounded-circle mt-1 pl-1"
                    size="sm"
                    onClick={() => setMostrar(true)}
                >
                    <FontAwesomeIcon icon={faInfoCircle} />
                </Button>

            )}

            <hr style={{ color: "green" }} />

            <div
                className="container rounded pt-3 pb-3 w-40 shadow"
                style={{ background: "white" }}
            >
                <div className="text-center">
                    <h4> Ingresar Número de Orden de Flete </h4>
                </div>

                {/* FORMULARIO */}

                <Form
                    className="row d-flex justify-content-center w-80"
                    onSubmit={handleSubmit}
                >
                    <div className="w-50">

                        <Form.Control
                            type="text"
                            placeholder="Ej: 12345678"
                            pattern="\s*^\d{1,9}$+\s*"
                            onChange={handleChange}
                            required
                        />

                        {" "} &nbsp;

                        <label>Nº Orden de Flete</label>
                    </div>

                    <div className="text-center">


                        {MostrarBoton ? (<>

                            <button className=" btn btn-success shadow ">
                                <FontAwesomeIcon icon={faSearch} />
                                {" "}

                                Buscar OF
                                {" "}
                            </button>

                        </>) : (<>
                            <button className=" btn btn-outline-success shadow " disabled>
                                <FontAwesomeIcon icon={faSearch} />
                                {" "}
                                Buscar OF
                                {" "}
                            </button>

                        </>)


                        }



                    </div>
                </Form>
            </div>

            <hr style={{ color: "green" }} />

            {/* MODAL LOADING */}

            <Modal
                show={showU}
                size="sm"
                backdrop="static"
                keyboard={false}
            >

                <Modal.Body>

                    <div className="container text-center">
                        <Spinner animation="border" role="status" variant="success" size="sm" />
                        <Spinner animation="border" role="status" variant="success" />
                    </div>
                    <div className="container text-center">
                        <h6 style={{ color: "green" }}>Cargando...</h6>
                    </div>

                </Modal.Body>

            </Modal>

        </>
    )
}